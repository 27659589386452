<template>
  <div class="wallet-send">
    <div class="container-fluid mt--5">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0">Payment</h3>
        </div>
        <div v-if="allowedTrade == 'no'" class="card-body">
          <div class="mb-4">
            <div class="card p-2 mt-2 mb-3 alert-danger">
              <div class="text-center">
                <span>Your account can't use this function</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="allowedTrade != 'no'"
          class="card-body align-items-center"
          v-loading="loading"
        >
          <el-alert
            :title="`KYC Status ${kycStatus}`"
            type="error"
            :description="'Please finish your KYC on AB app'"
            :closable="false"
            v-if="kycStatus && kycStatus != 'approve'"
          >
          </el-alert>
          <el-form
            v-if="kycStatus && kycStatus == 'approve'"
            ref="formData"
            :model="formData"
            :rules="formRules"
          >
            <div v-if="rateofftime" class="row justify-content-md-center">
              <div class="col-12 col-lg-8 col-xl-4 mb-4">
                <el-alert
                  :title="rateofftime_message"
                  type="error"
                  :closable="false"
                >
                </el-alert>
              </div>
            </div>
            <div class="row justify-content-md-center">
              <div class="col-12 col-lg-6 col-xl-4">
                <div class="card p-2 mb-3 card-wallet">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">From</h4>
                  </div>
                  <wallet-select
                    ref="walletFrom"
                    :ignore-currency="wallet_to_currency"
                    @onChange="selectFrom"
                    @onLoaded="loadedWallet"
                  />
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-4">
                <div class="card p-2 mb-3 card-wallet">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">To</h4>
                  </div>
                  <payee-select
                    ref="payeeTo"
                    :ignore-currency="wallet_from_currency"
                    @onChange="selectTo"
                    @onLoaded="loadedPayee"
                  />
                </div>
              </div>
              <div class="col-12 text-center mb-2">
                <el-button @click="resetForm" size="small">Reset</el-button>
              </div>
              <div
                v-if="wallet_from_currency && wallet_to_currency"
                class="col-12 col-lg-8 col-xl-6"
              >
                <div class="card p-2 mb-3">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">
                      Amount
                      {{
                        paymentType == "sell"
                          ? wallet_from_currency
                          : wallet_to_currency
                      }}
                    </h4>
                  </div>
                  <div class="mt-2">
                    <div class="text-xs">You want to Sell or Buy?</div>
                    <el-form-item label="">
                      <el-radio-group
                        v-model="paymentType"
                        @change="amountEnter()"
                      >
                        <el-radio label="sell" border size="medium">Sell</el-radio>
                        <el-radio label="buy" border size="medium">Buy</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <free-trade-list @onChanged="onSelectFreeTrade"></free-trade-list>
                 
                    <el-form-item label="" prop="amount">
                      <el-input
                        class="big-input"
                        type="number"
                        placeholder="Please enter amount"
                        v-model="formData.amount"
                        @input="amountEnter()"
                      >
                      </el-input>
                    </el-form-item>

                    <div>
                      <QuoteButton
                        ref="quotebtn"
                        :customertype="'normal'"
                        :from="wallet_from_currency"
                        :to="wallet_to_currency"
                        :amount="formData.amount"
                        :fx="false"
                        :domestic="payment_domestic"
                        :paymenttype="paymentType"
                        :freeuid = "freeuid"
                        @onQuoted="onQuoted"
                        @onTimeout="onTimeout"
                      />
                    </div>

                    <el-form-item label="Purpose of payment" prop="purpose">
                      <select-purpose
                        v-model="formData.purpose"
                      ></select-purpose>
                    </el-form-item>

                    <el-form-item
                      label="Other Purpose"
                      prop="purposeOther"
                      v-if="formData.purpose === 'Other'"
                    >
                      <el-input
                        v-model="formData.purposeOther"
                        v-input-filter
                        placeholder="Other Purpose"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Reference" prop="reference">
                      <el-input
                        v-model="formData.reference"
                        v-input-filter
                        placeholder="Reference"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>

              <div class="col-12 text-center" v-if="quoted">
                <el-button
                  type="primary"
                  round
                  :loading="submitting"
                  :disabled="submitting"
                  @click="onSubmit"
                  >Confirm</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <WalletReceipt
      ref="WalletReceipt"
      :create-favorite="true"
      @onClose="onCloseReceipt"
    />
    <ReviewRateDialog ref="reviewratedialog" @onClose="gotoAccount"/>
  </div>
</template>

<script>
import WalletSelect from "@/views/Wallet/components/WalletSelect/WalletSelect.vue";
import PayeeSelect from "@/views/Wallet/components/PayeeSelect/PayeeSelect.vue";
import QuoteButton from "@/views/Wallet/components/QuoteButton.vue";
import WalletPayment from "@/api/wallet/payment";
import SelectPurpose from "@/components/Input/SelectPurpose";
import WalletReceipt from "@/views/Wallet/components/WalletReceipt";
import AccountApi from "@/api/wallet/account";
import FreeTradeList from '@/components/FreeTrade/FreeTradeList.vue';
import ReviewRateDialog from '@/components/Review/Review'
export default {
  components: {
    WalletSelect,
    PayeeSelect,
    QuoteButton,
    SelectPurpose,
    WalletReceipt,
    FreeTradeList,
    ReviewRateDialog
  },
  computed: {
    allowedTrade() {
      return this.$store.getters.allowedtrade;
    },
    reviewState() {
      return this.$store.getters.reviewstate;
    },
  },
  data() {
    return {
      submitting: false,
      wallet_from_currency: "",
      wallet_to_currency: "",
      payment_domestic: false,
      loading: false,
      quoted: false,
      rateofftime: false,
      rateofftime_message: "",
      paymentType: "sell",
      kycStatus: "",
      freeuid:"",
      paymentReceipt: {},
      formData: {
        from_bankholderid: "",
        currency_from: "",
        payee_uid: "",
        amount: "",
        fee: "",
        rate: "",
        reference: "",
        exchangerate_type: "normal",
        use_fasttrack: false,
        purpose: "",
        purposeOther: "",
        idempotency: "",
        verifytoken: "",
        qoutetoken: "",
        calresponse: {},
      },
      formRules: {
        amount: [
          { required: true, message: "Please enter Amount", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message: "Please enter reference",
            trigger: "blur",
          },
          {
            message: " Reference must put 6-18 character ",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
        purpose: [
          { required: true, message: "Please enter purpose", trigger: "blur" },
        ],
        purposeOther: [
          {
            required: true,
            message: "Please enter other purpose",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getKyc() {
      AccountApi.getKycState().then(({ result, data }) => {
        if (result) {
          this.kycStatus = data["status"];
        }
      });
    },
    paymentOfftime() {
      this.loading = true;
      WalletPayment.paymentOfftime().then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          this.rateofftime = data["offtime"];
          this.rateofftime_message = data["message"];
        }
      });
    },
    selectFrom(wallet) {
      this.formData.from_bankholderid = wallet.bankholderID;
      this.formData.currency_from = wallet.currency;
      this.wallet_from_currency = wallet.currency;
      this.amountEnter();
      this.$emit("changeFrom", wallet.currency);
    },
    selectTo(payee) {
      this.formData.payee_uid = payee.uid;
      this.formData.reference = payee.reference;
      this.wallet_to_currency = payee.currency;
      this.payment_domestic = ["GBP", "EUR", "THB"].includes(payee.currency)
        ? payee.domestic === 1
        : false;
      this.amountEnter();
      this.$emit("changeTo", payee.currency);
    },
    amountEnter() {
      if (this.$refs.quotebtn) this.$refs.quotebtn.clearTimer();
    },
    onQuoted(data) {
      this.formData.verifytoken = data["verifytoken"];
      this.formData.qoutetoken = data["qoutetoken"];
      this.formData.calresponse = data["response"];
      this.formData.rate = parseFloat(this.formData.calresponse.rate);
      this.formData.fee = parseFloat(this.formData.calresponse.fee);
      this.formData.use_fasttrack = data.fasttrack;
      this.quoted = true;
      this.$emit("reloadRate");
    },
    onSelectFreeTrade(freeuid){
      this.amountEnter();
      this.freeuid = freeuid;
    },
    onTimeout() {
      this.quoted = false;
    },

    onSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.submitting = true;
          let model = JSON.parse(JSON.stringify(this.formData));
          model.amount = parseFloat(this.formData.amount);
          WalletPayment.paymentWallet(model).then(
            ({ result, data, message }) => {
              this.submitting = false;
              this.amountEnter();
              if (result) {
                this.paymentReceipt = data["receipt"];
                this.$refs.WalletReceipt.open(
                  this.paymentReceipt["payment_UID"],
                  "",
                  this.formData.from_bankholderid
                );
                
                
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submitting = false;
              this.amountEnter();
              if (err.response) {
                this.$swal("Message", err.response.data.message, "error");
              }
            }
          );
        }
      });
    },
    gotoAccount(){
      this.$router.push({
        path: `/wallet/account/${this.formData.currency_from}/${this.formData.from_bankholderid}`,
      });
    },
    onCloseReceipt() {
      if (this.reviewState){
        this.gotoAccount();
      }else{
        this.showReview();
      }
    },

    resetForm() {
      this.formData.from_bankholderid = "";
      this.formData.currency_from = "";
      this.wallet_from_currency = "";
      this.formData.payee_uid = "";
      this.formData.reference = "";
      this.wallet_to_currency = "";
      this.$refs.walletFrom.reset();
      this.$refs.payeeTo.reset();
    },
    loadedWallet() { 
      if (this.$route.query.from) {
        this.$refs.walletFrom.show(this.$route.query.from.toUpperCase());
      }
    },
    loadedPayee() { 
      if (this.$route.query.to) {
        this.$refs.payeeTo.show(this.$route.query.to);
      }
    },
    showReview(){
      this.$refs.reviewratedialog.show('autofx', this.paymentReceipt)
    }
  },
  mounted() {
    this.getKyc();
    this.paymentOfftime();
    

    
  },
};
</script>

<style lang="scss"></style>

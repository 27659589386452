<template>
  <div class="payee-item-select" style="width: 100%">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <el-image
          style="width: 48px;"
          :src="`/img/currency-flags/${payeeData.currency.toLowerCase()}.png`"
          fit="contain"
        ></el-image>
        <div style="margin-left:8px;">
          <div class="payee-account-number">{{payeeData.accountNumber}}</div>
          <div class="payee-bankname">{{payeeData.bankName}}</div>
          <div class="payee-name">{{getPayeeName}}</div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-right">
          <div class="p-0 font-weight-bold">
            {{payeeData.currency}}
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayeeSelectItem",
  props: {
    payeeData: {
      type: Object,
    },
  },
  
  computed:{
    getPayeeName() {
      return `${this.payeeData.title} ${this.payeeData.firstName} ${this.payeeData.lastName}`;
    },
  }
};
</script>

<style lang="scss">
.payee-item-select{
  line-height: 16px;
  .payee-account-number{
    font-size: 15px;
    font-weight: 700;
  }
  
}
</style>
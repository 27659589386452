<template>
  <el-popover
    popper-class="payee-select"
    placement="bottom"
    trigger="click"
    width="400"
    v-model="visible"
    @show="show"
  >
    <div class="currency-filter">
      <div class="currency-group" @click="filterByCurrency('')">
        <el-avatar size="small" style="background: #2dce89"> All </el-avatar>
      </div>

      <div
        v-for="currency of currencies"
        :key="currency"
        :class="`currency-group ${
          currencySelect == currency ? 'currency-group-active' : ''
        }`"
        @click="filterByCurrency(currency)"
      >
        <el-avatar
          size="small"
          fit="cover"
          :src="`/img/currency-flags/${currency.toLowerCase()}.png`"
        ></el-avatar>
      </div>
      <div v-if="accessRole.payee" class="add-payee">
        <el-dropdown v-if="!currencySelect" @command="addPayee" trigger="click">
          <span class="el-dropdown-link">
            <el-avatar size="small" style="background: #0086b9"> + </el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="currency of currencies"
              :key="currency"
              :command="currency"
              ><i class="el-icon-circle-plus"></i>
              <el-avatar
                class="dropdown-add-payee-list"
                size="small"
                fit="cover"
                :src="`/img/currency-flags/${currency.toLowerCase()}.png`"
              ></el-avatar>
              {{ currency }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <div
          v-if="currencySelect"
          class="el-dropdown"
          @click="addPayee(currencySelect)"
        >
          <div class="el-dropdown-link">+ New</div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <el-input
        v-model="search"
        size="mini"
        placeholder="Search"
      ></el-input>
    </div>
    <div class="el-scrollbar">
      <div
        class="el-select-dropdown__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default"
      >
        <ul class="el-scrollbar__view el-select-dropdown__list">
          <li
            class="el-select-dropdown__item border-bottom pt-2 pb-2 h-100"
            v-for="payee of payeeListsItem.filter(
              (data) =>
                !search ||
                data.firstName.toLowerCase().includes(search.toLowerCase()) ||
                data.accountNumber.toLowerCase().includes(search.toLowerCase()) ||
                data.bankName.toLowerCase().includes(search.toLowerCase()) ||
                data.sortcode.toLowerCase().includes(search.toLowerCase()) 
            )"
            :key="payee.uid"
            @click="selecteItem(payee)"
          >
            <payee-select-item :payee-data="payee" />
          </li>
        </ul>
      </div>
    </div>
    <div slot="reference" class="el-input cursor-pointer" v-loading="loading">
      <div class="el-input__inner payee-active h-100">
        <payee-select-item v-if="payeeSelect" :payee-data="payeeSelect" />
      </div>
      <span class="el-input__suffix"
        ><span class="el-input__suffix-inner"
          ><i
            :class="`el-select__caret el-input__icon ${
              visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
            }`"
          ></i></span
      ></span>
    </div>
    <payee-gbp-form ref="PayeeGbpForm" v-on:submited="createSubmited" />
    <payee-eur-form ref="PayeeEurForm" v-on:submited="createSubmited" />
    <payee-usd-form ref="PayeeUsdForm" v-on:submited="createSubmited" />
    <payee-thb-form ref="PayeeThbForm" v-on:submited="createSubmited" />
    <payee-form ref="PayeeForm" v-on:submited="createSubmited" />
  </el-popover>
</template>

<script>
import Utils from "@/utils/";
import WalletApi from "@/api/wallet/wallet";
import WalletPayeeApi from "@/api/wallet/payee";
import PayeeSelectItem from "./PayeeSelectItem.vue";
import PayeeGbpForm from "@/views/Wallet/Payee/components/PayeeGbpForm.vue";
import PayeeEurForm from "@/views/Wallet/Payee/components/PayeeEurForm.vue";
import PayeeUsdForm from "@/views/Wallet/Payee/components/PayeeUsdForm.vue";
import PayeeThbForm from "@/views/Wallet/Payee/components/PayeeThbForm.vue";
import PayeeForm from "@/views/Wallet/Payee/components/PayeeForm.vue";
export default {
  name: "PayeeSelect",
  props: {
    ignoreCurrency: {
      type: String,
      default: "",
    },
  },
  components: {
    PayeeSelectItem,
    PayeeGbpForm,
    PayeeEurForm,
    PayeeUsdForm,
    PayeeThbForm,
    PayeeForm,
  },
  computed:{
    accessRole() {
      return this.$store.getters.accessrole;
    },
  },
  data() {
    return {
      loading: false,
      payeeLists: [],
      payeeListsItem: [],
      payeeSelect: null,
      visible: false,
      currencies: [],
      currencySelect: "",
      search: "",
    };
  },
  methods: {
    getWallet() {
      this.loading = true;
      WalletApi.getCurrencyActiveList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          data.forEach((item) => {
            this.currencies.push(item.currency.toUpperCase());
          });
          this.$emit("onLoaded");
        }
      });
    },
    getPayee(uid = "") {
      this.loading = true;
      this.payeeLists = [];
      this.payeeListsItem = [];
      WalletPayeeApi.getPayeeList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          data.forEach((item) => {
            if (this.ignoreCurrency != item.currency) {
              this.payeeLists.push(item);
              this.payeeListsItem.push(item);
            }
          });

          if (this.currencySelect) {
            this.filterByCurrency(this.currencySelect);
          }

          if (uid) {
            this.selectActive(uid);
          }
        }
      });
    },

    filterByCurrency(currency = "") {
      this.currencySelect = currency;
      this.payeeListsItem = !currency
        ? this.payeeLists
        : Utils.findObjectArray(this.payeeLists, currency, "currency");
    },
    selecteItem(payee) {
      this.payeeSelect = payee;
      this.visible = false;

      this.$emit("onChange", payee);
    },
    selectActive(uid) {
      this.payeeLists.forEach((item) => {
        if (item.uid == uid) {
          this.selecteItem(item);
        }
      });
    },
    show(uid = "") {
      this.getPayee(uid);
    },
    createSubmited(payee) {
      this.getPayee();
      if (payee) {
        this.selecteItem(payee);
      }
    },
    addPayee(currency) {
      switch (currency) {
        case "GBP":
          this.$refs.PayeeGbpForm.createForm();
          break;
        case "EUR":
          this.$refs.PayeeEurForm.createForm();
          break;
        case "THB":
          this.$refs.PayeeThbForm.createForm();
          break;
        case "USD":
          this.$refs.PayeeUsdForm.createForm();
          break;

        default:
          this.$refs.PayeeForm.createForm(currency);
          break;
      }
    },

    reset() {
      this.payeeSelect = null;
      this.currencySelect = "";
    },
  },
  mounted() {
    this.getWallet();
  },
};
</script>

<style lang="scss">

.payee-active {
  padding: 5px;
  padding-right: 30px;
  min-height: 60px;
}

    .add-payee {
    display: inline-block;
    vertical-align: middle;

    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    margin: 10px;
    transition: 0.3s;
    padding: 5px;
    &:hover {
      background-color: #2dce89;
    }
    .el-dropdown {
      border-radius: 50px;
      background-color: #1991c0;
      color: #fff;
      .el-dropdown-link {
        padding: 5px;
      }
    }
  }

.currency-filter::-webkit-scrollbar {
  height: 5px;
  scroll-behavior: smooth;
}
.currency-filter {
  overflow: auto;
  white-space: nowrap;
  .currency-group {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
    transition: 0.3s;
    &:hover {
      background-color: #2dce89;
    }
    .el-avatar {
      display: inline-block;
      vertical-align: middle;
      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
  .currency-group-active {
    background-color: #2dce89;
  }
}
</style>